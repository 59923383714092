import { loadStripe } from "@stripe/stripe-js";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

export var offerID = "test_offer";
export const useTestPayments = true;

if (process.env.NODE_ENV === "development" && useTestPayments) {
	offerID = "TEST_VIP_BASIC_PRIVATE_monthly_annual";
	// offerID = "test_offer";
	// offerID = "BASIC_VIP_monthly";
} else {
	offerID = "VIP_BASIC_PRIVATE_monthly_annual";
}

var stripePublicKey;

if (process.env.NODE_ENV === "development" && useTestPayments) {
	stripePublicKey = "pk_test_NNYnjbsmdeDu2WNKpSeewx4N001Sipda9v";
} else {
	stripePublicKey = "pk_live_7ScY4nhC9qpt1QfEJBDiLb0d003uLJco4F";
}

export const stripePromise = loadStripe(stripePublicKey);
